import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivationEnd, Router, RouterOutlet } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { filter, take } from "rxjs/operators";
import { DatePipe, TitleCasePipe } from "@angular/common";
import _ from "lodash";
import moment from "moment";

import { Constants } from "src/app/constants/constants";
import { KeyMap, UserPermissions } from "src/app/models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { SharedService } from "src/app/services/shared.service";
import { TitleService } from "src/app/services/title.service";
import { UsersService } from "../../account-management/users/users.service";
import { Incident } from "../incident";
import { IncidentsService } from "../incidents.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { IncidentsNameColumnComponent } from "src/app/components/shared/table-list/tables-components/incidents-name-column/incidents-name-column.component";
import { assignIncidentNameInputsFactory } from "src/app/components/shared/table-list/tables-components/incidents-name-column/incidents-name-column.table-adapter.ts";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";

@Component({
    selector: "app-incident-list",
    templateUrl: "./incident-list.component.html",
    providers: [TitleCasePipe, DatePipe]
})
export class IncidentListComponent implements OnInit, OnDestroy {
    @ViewChild(RouterOutlet) incident: RouterOutlet;
    refreshing = false;
    incidents: Incident[] = [];
    incidentID: number;
    selectedRows: Array<Incident> = [];

    userPermissions: UserPermissions;
    urls = Constants.urls;
    currentSortDirection: string;

    private routeSubscription: Subscription;
    private incidentsSubscription: Subscription;
    private incidentsRefreshSubscription: Subscription;
    private incidentsBS$ = new BehaviorSubject<Incident[]>([]);

    zeroDate = "0000-00-00 00:00:00";
    dateFormat = "M/d/YY, h:mm a";
    dateFormat2 = "MM/DD/YYYY HH:mm:ss";

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 240,
            sticky: 1,
            component: IncidentsNameColumnComponent,
            assignComponentsInputs: assignIncidentNameInputsFactory,
            sortBy: (row: KeyMap<Incident>) => row.name,
            textValue: (row: KeyMap<Incident>) => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("STATE"),
            columnDef: "state",
            width: 70,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => this.translate.instant(row._frontData.runtime_state),
                row => this.translate.instant(row._frontData.runtime_state),
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => this.translate.instant(row._frontData.runtime_state),
            textValue: (row: KeyMap<Incident>) => this.translate.instant(row._frontData.runtime_state),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: (row: KeyMap<Incident>) => this.translate.instant(row._frontData.runtime_state),
            columnSelectOptions: ["Open", "Closed"]
        },
        {
            header: this.translate.instant("START_TIME"),
            columnDef: "startTime",
            width: 160,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<KeyMap<Incident>>(
                row => row.start_time,
                this.dateFormat // format
            ),
            sortBy: (row: KeyMap<Incident>) => new Date(row.start_time).getTime(),
            textValue: (row: KeyMap<Incident>) => {
                if (row.start_time !== "0000-00-00 00:00:00") {
                    const startTimeDate = new Date(row.start_time);
                    const dateISOString = startTimeDate.toISOString();
                    return this.datePipe.transform(dateISOString, this.dateFormat);
                }
                return "-";
            }
        },
        {
            header: this.translate.instant("DURATION"),
            columnDef: "duration",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => row._frontData.duration,
                row => row._frontData.duration,
                () => true
            ),
            sortBy: (row: Incident) => row._frontData.durationSort?.asMilliseconds() || 0,
            textValue: (row: Incident) => row._frontData.durationSort?.asMilliseconds() || 0,
            columnFilterType: ColumnFilterType.DURATION,
            columnFilterValue: (row: Incident) => row._frontData.durationSort?.asSeconds()
        },
        {
            header: this.translate.instant("ROOT_CAUSE"),
            columnDef: "rootCause",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => row.likely_cause || "-",
                row => row.likely_cause || "-",
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => row.likely_cause || "-",
            textValue: (row: KeyMap<Incident>) => row.likely_cause || "-",
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("DESCRIPTION"),
            columnDef: "description",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => row.triggering_error_message,
                row => row.triggering_error_message,
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => row.triggering_error_message,
            textValue: (row: KeyMap<Incident>) => row.triggering_error_message,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("#_OF_OBJECTS_AFFECTED"),
            columnDef: "objectsCount",
            width: 80,
            visible: true,
            align: "right",
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => this.getObjectCountColumnTextByRow(row),
                row => this.getObjectCountColumnTextByRow(row),
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => row.objectsCount,
            textValue: (row: KeyMap<Incident>) => this.getObjectCountColumnTextByRow(row),
            columnFilterType: ColumnFilterType.NUMBER
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 40,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<Incident>({
                deleteRef: incident => this.deleteIncident(incident),
                canDeleteCallBack: () =>
                    this.userPermissions.is_admin ||
                    this.userPermissions.is_zixi_support_write ||
                    this.userPermissions.is_zixi_admin ||
                    this.userPermissions.is_incident_manager
            })
        },
        {
            header: this.translate.instant("ONGOING"),
            columnDef: "ongoing",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: (row: KeyMap<Incident>) => (row.end_time ? "No" : "Yes"),
            columnSelectOptions: ["Yes", "No"]
        }
    ];

    constructor(
        private router: Router,
        private is: IncidentsService,
        public sharedService: SharedService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private modalService: ModalService,
        private titleCasePipe: TitleCasePipe,
        private datePipe: DatePipe
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("INCIDENTS"));
        // Route Subscription
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.id)
                    this.incidentID = parseInt(event.snapshot.params.id, 10);
                else this.incidentID = null;
            });
    }

    ngOnInit(): void {
        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");

        this.setDateRangeOnLoad();

        this.incidentsSubscription = this.is.incidents.subscribe(incidents => {
            this.incidents = incidents;
            if (this.incidents) {
                this.prepTableData();
            }
        });

        // Start Auto Refresh
        this.startIncidentsRefresh();
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.incidentsSubscription.unsubscribe();
        this.stopIncidentsRefresh();
    }

    private getObjectCountColumnTextByRow(incident: Incident) {
        return incident.objectsCount ? incident.objectsCount.toString() : "-";
    }

    selectRow = (selectedIncident: Incident) => {
        this.router.navigate([Constants.urls.incidents, selectedIncident.id]);
    };

    async refresh() {
        this.refreshing = true;
        const incidentsRefresh = this.is.getIncidents(true).toPromise();
        const incidentRefresh = this.incidentID ? this.is.refreshIncident(this.incidentID, true).toPromise() : null;

        await Promise.all([incidentsRefresh, incidentRefresh]);
        this.refreshing = false;
    }

    private async multiAction(action: string, func: (incident: Incident) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "INCIDENT", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple incidents");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (incident: Incident) => this.is.deleteIncident(incident));
    }

    async deleteIncident(incident: Incident) {
        await this.modalService.confirm(
            "DELETE",
            "INCIDENT",
            async () => {
                const result = await this.is.deleteIncident(incident);
                if (result) {
                    this.mixpanelService.sendEvent("delete incident", { id: incident.id });
                    this.is.getIncidents(true);
                } else {
                    return false;
                }
            },
            incident.name
        );
    }

    startIncidentsRefresh() {
        this.incidentsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopIncidentsRefresh() {
        this.incidentsRefreshSubscription.unsubscribe();
    }

    get incidents$() {
        return this.incidentsBS$.asObservable();
    }

    private prepTableData() {
        if (this.incidents) {
            let incidents: Incident[] = [...this.incidents];

            incidents = incidents.filter(i => {
                const startTime = moment(i.start_time);
                if (startTime.isBetween(this.fromDate, this.toDate)) return i;
                else return false;
            });

            if (!this.currentSortDirection) {
                this.sharedService.sort(incidents, "start_time", "desc");
            }

            this.incidentsBS$.next(incidents);
        }
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    // Datepicker
    showDatePicker = false;
    showFrom = true;
    pickerCounter = 0;
    toDate: moment.Moment;
    fromDate: moment.Moment;
    toDateString: string;
    fromDateString: string;
    dateRange: string = null;
    datePresets = ["LAST_12_HOURS", "LAST_24_HOURS", "LAST_48_HOURS", "LAST_7_DAYS", "LAST_14_DAYS", "LAST_30_DAYS"];

    toggleDatePicker() {
        this.showDatePicker = true;
    }

    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }

    clickOutsidePicker() {
        this.pickerCounter = this.pickerCounter + 1;
        if (this.pickerCounter > 1) {
            this.closeDatePicker();
        }
    }

    closeDatePicker() {
        window.focus();
        this.pickerCounter = 0;
        this.showDatePicker = false;
        this.showFrom = true;
    }

    setDateRangeFromPreset(preset?: string) {
        this.toDate = moment();
        this.toDateString = moment().format();

        if (!preset) {
            this.dateRange = this.translate.instant("LAST_12_HOURS");
            this.fromDate = moment().subtract(12, "hours");
        } else {
            localStorage.setItem(`incidents.presetDate`, preset);
            localStorage.removeItem(`incidents.fromDateString`);
            localStorage.removeItem(`incidents.toDateString`);

            this.dateRange = this.translate.instant(preset);
            if (preset === "LAST_12_HOURS") {
                this.fromDate = moment().subtract(12, "hours");
            }
            if (preset === "LAST_24_HOURS") {
                this.fromDate = moment().subtract(24, "hours");
            }
            if (preset === "LAST_48_HOURS") {
                this.fromDate = moment().subtract(48, "hours");
            }
            if (preset === "LAST_7_DAYS") {
                this.fromDate = moment().subtract(7, "days");
            }
            if (preset === "LAST_14_DAYS") {
                this.fromDate = moment().subtract(14, "days");
            }
            if (preset === "LAST_30_DAYS") {
                this.fromDate = moment().subtract(30, "days");
            }
        }
        this.prepTableData();
    }

    setDateRangeFromPicker() {
        localStorage.setItem(`incidents.fromDateString`, this.fromDateString);
        localStorage.setItem(`incidents.toDateString`, this.toDateString);
        localStorage.removeItem(`incidents.presetDate`);

        const from: string = moment(this.fromDateString).format(this.dateFormat2);
        const to: string = moment(this.toDateString).format(this.dateFormat2);

        this.fromDate = moment(this.fromDateString);
        this.toDate = moment(this.toDateString);

        this.dateRange = from + " - " + to;
        this.prepTableData();
    }

    setDateRangeOnLoad() {
        const fromDateString = localStorage.getItem(`incidents.fromDateString`);
        const toDateString = localStorage.getItem(`incidents.toDateString`);
        const preset = localStorage.getItem(`incidents.presetDate`);

        if (preset) this.setDateRangeFromPreset(preset);
        else if (fromDateString && toDateString) {
            this.fromDateString = fromDateString;
            this.toDateString = toDateString;
            this.setDateRangeFromPicker();
        } else this.setDateRangeFromPreset("LAST_30_DAYS");
    }

    fromDateChanged(event: string) {
        if (event !== null) {
            this.setDateRangeFromPicker();
        }
    }

    toDateChanged(event: string) {
        if (event !== null) {
            this.setDateRangeFromPicker();
        }
    }
}
