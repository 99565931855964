<div class="panel-container">
    <div id="left-container" class="left-container" #leftContainer>
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 translate title="{{ 'INCIDENTS' | translate }}">INCIDENTS</h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!incidentID">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>

        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel [ngClass]="{ rowSelected: incidentID }">
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'incident'"
                    [displayTableName]="'INCIDENTS' | translate"
                    [data]="incidents$ | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (rowSelected)="selectRow($event)"
                    (currentSortDirection)="onSort($event)"
                    [showReport]="false"
                >
                    <!-- Calendar -->
                    <div class="form-group calendar-group position-relative mb-0 d-flex">
                        <label for="dateRange" class="d-none d-xs-flex">{{ "DATE_RANGE" | translate }}&nbsp;&nbsp;</label>
                        <div class="input-group">
                            <input
                                class="form-control dateRange"
                                id="dateRange"
                                name="dateRange"
                                (click)="toggleDatePicker()"
                                [(ngModel)]="dateRange"
                                placeholder="{{ 'SELECT_DATE/TIME_RANGE' | translate }}"
                                (keydown)="onlyDelete($event)"
                                autocomplete="off"
                                title="{{ dateRange }}"
                            />
                            <button
                                class="btn btn-outline-primary d-print-none"
                                (click)="toggleDatePicker()"
                                type="button"
                                title="{{ 'SELECT_DATE/TIME' | translate }}"
                            >
                                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                            </button>
                        </div>
                        <div class="datepicker-container absolute" *ngIf="showDatePicker" (clickOutside)="clickOutsidePicker()">
                            <div class="arrow"></div>
                            <div class="datepicker-inner-container shadow-sm">
                                <fa-icon icon="times-circle" class="close-icon" (click)="closeDatePicker()"></fa-icon>
                                <div class="date-navigation">
                                    <ul class="nav nav-pills nav-fill">
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" (click)="showFrom = true" [ngClass]="{ active: showFrom }">{{
                                                "FROM" | translate
                                            }}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link" (click)="showFrom = false" [ngClass]="{ active: !showFrom }">{{
                                                "TO" | translate
                                            }}</a>
                                        </li>
                                    </ul>
                                    <div class="date-container">
                                        <div class="datepicker" *ngIf="showFrom">
                                            <zx-date-time-picker
                                                name="fromDate"
                                                [(ngModel)]="fromDateString"
                                                (ngModelChange)="fromDateChanged($event)"
                                            ></zx-date-time-picker>
                                        </div>
                                        <div class="datepicker" *ngIf="!showFrom">
                                            <zx-date-time-picker
                                                name="toDate"
                                                [(ngModel)]="toDateString"
                                                (ngModelChange)="toDateChanged($event)"
                                            ></zx-date-time-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="date-presets">
                                    <h5>{{ "RANGE" | translate }}</h5>
                                    <ul class="list-group">
                                        <li
                                            *ngFor="let preset of datePresets"
                                            href="javascript:void(0)"
                                            (click)="setDateRangeFromPreset(preset)"
                                            class="list-group-item list-group-item-action"
                                            [ngClass]="{ active: (preset | translate) === dateRange }"
                                        >
                                            {{ preset | translate }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!incidentID && selectedRows.length > 0">
                        <app-zx-action-buttons
                            [toggleMuteButton]="false"
                            [toggleStateButton]="false"
                            [editButton]="false"
                            [enableButton]="false"
                            (multiDelete)="multiDelete($event)"
                        ></app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
    <zx-list-resize *ngIf="incidentID" (resizing)="resizing($event)"></zx-list-resize>
</div>
