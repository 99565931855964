import { Component, OnInit } from "@angular/core";
import { Location, TitleCasePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "../../../services/shared.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { ClipboardService } from "ngx-clipboard";

import { Incident } from "../incident";
import { IncidentsService } from "../incidents.service";

@Component({
    selector: "app-incident-form",
    templateUrl: "./incident-form.component.html",
    providers: [TitleCasePipe]
})
export class IncidentFormComponent implements OnInit {
    incident: Incident;
    existingIncident: Incident;
    incidentID: number;

    action: string;
    loading = true;
    saving = false;

    submitted = false;
    minLength = 3;
    maxLength = 200;
    isEdit = false;
    constants = Constants;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private sharedService: SharedService,
        private modalService: ModalService,
        private is: IncidentsService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        private cbs: ClipboardService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.incidentID = +params.get("id");
            this.action = params.get("action");

            if (this.incidentID) {
                this.incident = Object.assign({}, this.is.getCachedIncident(this.incidentID));
                this.existingIncident = _.cloneDeep(this.incident);
                this.prepForm();
                // Check if incident found in cache, if not get incidents
                if (this.sharedService.isEmptyObject(this.incident)) {
                    this.is
                        .getIncidents(true)
                        .pipe(take(1))
                        .subscribe(async () => {
                            this.incident = Object.assign({}, this.is.getCachedIncident(this.incidentID));
                            this.incident = await this.is.refreshIncident(this.incident.id, true).toPromise();
                            this.existingIncident = _.cloneDeep(this.incident);
                            this.prepForm();
                        });
                }
            }
        });
    }

    prepForm() {
        if (!this.action && !this.incident) {
            this.incident = new Incident();
        }

        if (this.action === "edit") this.isEdit = true;

        if (!this.incident && this.action) {
            return;
        }

        this.setTitle();
        this.loading = false;
    }

    setTitle() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("INCIDENT") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.incident && this.incident.name ? this.incident.name : "")
        );
    }

    ngOnInit() {
        this.prepForm();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.incident.name,
            likely_cause: this.incident.likely_cause,
            state: this.incident.state
        };

        // Edit Check
        if (this.isEdit) {
            const result = await this.is.updateIncident(this.incident.id, model);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("update incident", {
                    updated: Object.keys(model)
                });
                this.location.back();
            } else this.saving = false;
        } else {
            // New save
        }
    }

    cancel() {
        this.location.back();
    }
}
