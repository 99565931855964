import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { IncidentsComponent } from "./incidents.component";
import { IncidentListComponent } from "./incident-list/incident-list.component";
import { IncidentComponent } from "./incident/incident.component";

// Routes
import { IncidentsRouting } from "./incidents.routing";
import { IncidentFormComponent } from "./incident-form/incident-form.component";
import { IncidentDialogComponent } from "./incident-dialog/incident-dialog.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    declarations: [
        IncidentsComponent,
        IncidentListComponent,
        IncidentComponent,
        IncidentFormComponent,
        IncidentDialogComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        NgSelectModule,
        FontAwesome,
        SharedModule,
        IncidentsRouting,
        TourMatMenuModule,
        MatTooltipModule,
        MatTreeModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule
    ]
})
export class IncidentsModule {}
