import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";
import { Incident } from "../incident";

import { IncidentsService } from "../incidents.service";

@Injectable({
    providedIn: "root"
})
export class IncidentListResolverService implements Resolve<Incident[]> {
    constructor(private is: IncidentsService) {}

    resolve(): Observable<Incident[]> | Observable<never> {
        return Observable.create((observe: Subscriber<Incident[]>) => {
            this.is.getIncidents().subscribe((incidents: Incident[]) => {
                observe.next(incidents);
                observe.complete();
            });
        });
    }
}
