import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { IncidentsComponent } from "./incidents.component";
import { IncidentListComponent } from "./incident-list/incident-list.component";
import { IncidentComponent } from "./incident/incident.component";
import { IncidentFormComponent } from "./incident-form/incident-form.component";

import { IncidentListResolverService } from "./incident-list/incident-list-resolver.service";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";

const incidentRoutes: Routes = [
    {
        path: Constants.urls.incidents + "/:id/:action",
        component: IncidentFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.VIEWING_AN_INCIDENT",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/TYDWGAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.incidents,
        component: IncidentsComponent,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: "",
                component: IncidentListComponent,
                resolve: {
                    incident: IncidentListResolverService
                }
            },
            {
                path: ":id",
                component: IncidentComponent,
                canDeactivate: [PendingChangesGuard],
                resolve: {
                    incident: IncidentListResolverService
                }
            }
        ],
        data: {
            help: [
                {
                    menu: "HELP_MENU.INCIDENTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/G4DRGAE"
                },
                {
                    menu: "HELP_MENU.CREATING_AN_INCIDENT",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/HoDQGAE"
                },
                {
                    menu: "HELP_MENU.VIEWING_AN_INCIDENT",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/TYDWGAE"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(incidentRoutes)],
    exports: [RouterModule]
})
export class IncidentsRouting {}
